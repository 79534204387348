<template>
    <div class="modal" v-if="modelValue" :class="{'active' : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Product <span>Edit</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form class="forms-setting-form" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="edit-product-thumbnail-form">
                    <div class="setting_wpr" >
                        <h3 class="sub_header mb-2">Thumbnail</h3>
                        <image-library v-model="form.thumbnail" image-type="dashboard-thumb" :is-inside="true" />
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Product Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field type="text" name="name" v-model="form.name" placeholder="Product Name" rules="required" label="Product Name" autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Description</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                    <Field autocomplete="off" name="description" v-model="form.description" label="description">
                                        <textarea autocomplete="off" name="description" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="description" class="text-danger" />
                            </div>
                        </div>
                        <!-- <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Background Color</label>
                                <Field name="card_bg_color" v-model="form.card_bg_color" type="text" label="header background color" >
                                    <color-picker v-model="form.card_bg_color"/>
                                </Field>
                                <ErrorMessage name="card_bg_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Text Color</label>
                                <Field name="card_text_color" v-model="form.card_text_color" type="text" label="header background color">
                                    <color-picker v-model="form.card_text_color"/>
                                </Field>
                                <ErrorMessage name="card_text_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Icon Color</label>
                                <Field name="card_icon_color" v-model="form.card_icon_color" type="text" label="header background color">
                                    <color-picker v-model="form.card_icon_color"/>
                                </Field>
                                <ErrorMessage name="card_icon_color" class="text-danger" />
                            </div>
                        </div> -->
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                <button class="btn save_btn" type="button" @click="handleSubmit()">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Edit Checkout',

        data () {
            return {
                form: {
                    id: '',
                    name: '',
                    thumbnail: '',
                    description: '',
                    // card_bg_color: '#ffffff',
                    // card_text_color: '#121525',
                    // card_icon_color: '#2f7eed',
                }
            }
        },

        props: {
            modelValue: Boolean,
            selectedProduct: {
                type: Object,
                default: () => {},
            },
            refreshProduct: Function,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            loader: state => state.checkoutModule.checkoutLoader,
        }),

        methods: {
            ...mapActions({
                updateProduct: 'checkoutModule/updateProduct'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['edit-product-thumbnail-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleEditCheckout(form);
                    }
                });
            },

            handleEditCheckout (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.updateProduct(vm.form).then((result) => {
                    if (result) {
                        vm.refreshProduct();
                        vm.closeModal();
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.selectedProduct.id,
                    name: vm.selectedProduct.name,
                    thumbnail: vm.selectedProduct.thumbnail,
                    description: vm.selectedProduct.description,
                    // card_bg_color: '#ffffff',
                    // card_text_color: '#121525',
                    // card_icon_color: '#2f7eed',
                };
            }
        }
    }
</script>
